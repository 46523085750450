import { render, staticRenderFns } from "./ProductPackageView.vue?vue&type=template&id=3dba9717&scoped=true"
import script from "./ProductPackageView.vue?vue&type=script&lang=ts"
export * from "./ProductPackageView.vue?vue&type=script&lang=ts"
import style0 from "./ProductPackageView.vue?vue&type=style&index=0&id=3dba9717&prod&lang=css"
import style1 from "./ProductPackageView.vue?vue&type=style&index=1&id=3dba9717&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dba9717",
  null
  
)

export default component.exports